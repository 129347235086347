<template>
  <div class="overall-tournaments">
    <template v-if="tournamentsIds.length">
      <div class="overall-tournaments__list">
        <tournaments-list
          ref="tournamentsList"
          :tournaments-ids="tournamentsIds"
          :sort-order="dateOrder"
          @toggle-sort="onSortToggled"
        />
      </div>
      <pvp-pagination
        v-if="showPagination"
        :params="paginationParams"
        :disabled="loadingState.page"
        @onchange="setPage"
      />
    </template>
    <div v-else class="empty">
      <icon class="empty-icon" name="pvp-swords" />
      <div class="empty-text">
        {{ $t('tournaments.placeholders_filters') }}
      </div>
    </div>
  </div>
</template>

<script>
import TournamentsList from './TournamentsList.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'OverallTournaments',

  components: {
    Icon,
    TournamentsList,
  },
  props: {
    gamesIds: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tournamentsIds: [],

      // Pagination
      currentPage: 1,
      perPage: 12,
      total: 0,

      dateOrder: 'asc',
    };
  },
  computed: {
    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: [12, 36, 72],
      };
    },

    showPagination() {
      return this.total > 12;
    },
  },
  watch: {
    type: {
      handler(value) {
        if (['current', 'future'].includes(value)) {
          this.dateOrder = 'asc';
        }
        if (value === 'completed') {
          this.dateOrder = 'desc';
        }
        this.reloadTournaments(1);
      },
      immediate: true,
    },
    gamesIds: {
      handler() {
        this.reloadTournaments(1);
      },
    },
    tournamentsIds: {
      handler() {
        this.$emit('load', { amount: this.tournamentsIds.length });
      },
    },
  },
  methods: {
    ...mapActions('tournaments', ['fetchTournaments']),
    setPage({ page, perPage }) {
      this.perPage = perPage;
      this.reloadTournaments(page);
    },

    onSortToggled() {
      this.dateOrder = {
        asc: 'desc',
        desc: 'asc',
      }[this.dateOrder];

      this.reloadTournaments(1);
    },

    reloadTournaments(page) {
      this.currentPage = page;
      this.loadingState.page = true;
      this.loadTournaments();
    },

    loadTournaments: function () {
      this.fetchTournaments({
        type: this.type,
        gameId: this.gamesIds,
        page: this.currentPage,
        limit: this.perPage,
        tournamentTimeStart: this.dateOrder,
        isInRecommendedList:
          this.type === 'completed' ? undefined : 'desc',
      })
        .then((result) => {
          this.tournamentsIds = result.tournaments;
          this.total = result.pagination.total;
        })
        .finally(() => {
          this.loadingState.page = false;
          this.$nextTick(() => {
            this.scrollToElement({
              element: this.$refs?.tournamentsList?.$el,
              offset: -200,
            });
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.overall-tournaments {
  min-height: 200px;
  position: relative;

  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
  }

  .tournaments-list + .pagination {
    margin-top: 20px;
  }
}

.overall-tournaments__list {
  margin-bottom: 8px;
}

.loader {
  margin-top: 40px;
}

.empty {
  background-color: $dark-background;
  border-radius: 4px;
  box-shadow: $default-box-shadow;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-icon {
    font-size: 30px;
  }

  &-text {
    text-align: center;
    margin-top: 12px;
    font-weight: bold;
    font-size: 24px;
  }
}
</style>
