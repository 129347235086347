<template>
  <main-layout class="padded" :is-loading="loadingState.page">
    <div v-if="!loadingState.page" class="pvp-container">
      <headline>
        <h1 slot="title">{{ $t('tournaments.few') }}</h1>
      </headline>
      <tournaments-carousel
        :tournaments-ids="recommendedTournamentsIds"
      />
      <div class="all-tournaments">
        <GamesFilter
          v-model="gamesIds"
          @input="updateQuery"
          @filter-change="gamesFilterChangeHandler"
        />
        <TabList
          :key="tabListKey"
          :start-tab-index="currentTabIndex"
          :tabs="navigationTabs"
          @change-tab="tabListChangeHandler"
        />

        <profile-tournaments
          v-if="currentTab.currentProfile"
          :hide-filter="true"
          :game-id="gamesIds[0]"
          :hash="getCurrentProfileHash"
        />
        <overall-tournaments
          v-else
          :type="currentTab.type"
          :games-ids="gamesIds"
          @load="overallTournamentsOnLoad"
        />
      </div>
    </div>
    <template slot="seo-text">
      <p v-for="(seoTextItem, key) in seoText" :key="key">
        {{ seoTextItem }}
      </p>
    </template>
  </main-layout>
</template>

<script>
import { pushEvents } from '@utils/metricEvents.js';
import TabList from '@src/components/v2/TabList.vue';
import GamesFilter from '@components/GameComponents/GamesFilter.vue';
import TournamentsCarousel from '@components/TournamentComponents/TournamensCarousel.vue';
import ProfileTournaments from '@components/TournamentComponents/Lists/ProfileTournaments.vue';
import OverallTournaments from '@components/TournamentComponents/Lists/OverallTournaments.vue';

export default {
  name: 'Tournaments',
  components: {
    OverallTournaments,
    ProfileTournaments,
    TournamentsCarousel,
    GamesFilter,
    TabList,
  },
  page() {
    return {
      title: this.$t('tournaments.meta_title'),
      meta: [
        {
          description: this.$t('tournaments.meta_description'),
        },
      ],
    };
  },
  props: {
    gameCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      gamesIds: [],
      tournamentsIds: [],
      recommendedTournamentsIds: [],
      currentUserTournamentsCount: 0,
      currentTabIndex: 0,
      isCheckEmptyTab: true,
      tabListKey: 0,
    };
  },
  computed: {
    ...mapGetters('profile', ['isLoggedIn', 'getCurrentProfileHash']),

    tabs() {
      return [
        {
          title: this.$t('tournaments.tabs_upcoming'),
          type: 'future',
        },
        {
          title: this.$t('tournaments.tabs_current'),
          type: 'current',
          list: 'current',
        },
        {
          title: this.$t('tournaments.tabs_completed'),
          type: 'completed',
          list: 'completed',
        },
        {
          title: this.$t('tournaments.variant_my'),
          currentProfile: true,
          list: 'my',
        },
      ];
    },

    currentTab() {
      return this.tabs[this.currentTabIndex];
    },

    navigationTabs() {
      if (this.currentUserTournamentsCount) {
        return this.tabs;
      }

      return this.tabs.filter((tab) => !tab.currentProfile);
    },

    seoText() {
      return [
        this.$t('seo.tournaments_0', {
          host: window.location.host.toLocaleUpperCase(),
        }),
        this.$t('seo.tournaments_1'),
      ];
    },
  },
  created() {
    const { games } = this.$route.query;

    if (games) {
      this.gamesIds =
        games
          .toString()
          ?.split(',')
          ?.map((id) => Number(id)) || games;
    }

    this.updateQuery();

    if (this.isLoggedIn) {
      this.fetchProfileTournaments({
        hash: this.getCurrentProfileHash,
        limit: 12,
      }).then((res) => {
        this.currentUserTournamentsCount = _.get(
          res,
          'pagination.total',
          0,
        );
      });
    }

    this.fetchRecommendedTournaments()
      .then((ids) => {
        this.recommendedTournamentsIds = ids;
      })
      .finally(() => {
        this.loadingState.page = false;
      });
  },
  methods: {
    ...mapActions('tournaments', [
      'fetchProfileTournaments',
      'fetchRecommendedTournaments',
    ]),

    overallTournamentsOnLoad({ amount }) {
      if (!this.isCheckEmptyTab) return;
      if (amount || this.currentTab.type === 'completed') {
        this.isCheckEmptyTab = false;
        this.tabListKey += 1;
        return;
      }

      this.currentTabIndex += 1;
    },

    tabListChangeHandler(data) {
      this.currentTabIndex = data.index;
      this.updateQuery();
    },

    gamesFilterChangeHandler(gameName) {
      // ======================================================
      // METRICS Турниры. Клик по элементу блока популярные игры на турнирах
      const tmrParams = {
        category: 'pvp',
        action: 'tourn_popgames_click',
        label: gameName,
      };
      pushEvents('pvp_tourn_popgames_click', tmrParams);
      // ======================================================
    },

    updateQuery() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          games: this.gamesIds?.length
            ? this.gamesIds?.join(',')
            : undefined,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.tournaments-carousel {
  margin-top: 40px;

  @include max-tablet() {
    margin-top: 30px;
  }

  .tournaments-carousel {
    margin-top: 20px;
  }
}

.all-tournaments {
  margin-top: 40px;

  @include max-tablet() {
    margin-top: 30px;
  }

  .games-filter {
    margin-bottom: 20px;
  }

  .games-filter,
  .overall-tournaments,
  .profile-tournaments {
    margin-top: 20px;
  }

  .tournaments-filter {
    margin-top: 20px;

    @include max-tablet() {
      margin-left: -12px;
      margin-right: -12px;
    }
  }

  .tab-info {
    font-size: 16px;
    opacity: 0.4;
  }
}

.description-section {
  margin-top: 30px;
  font-size: 14px;
}
</style>
